<template>
  <div>
    <h1>{{ $t('registration.create.title') }}</h1>
    <p>{{ $t('registration.create.desc') }}</p>

    <v-form
      @submit.prevent="submit"
      v-model="isFormValid"
    >
      <!-- FIRSTNAME -->
      <v-text-field
        id="firstName"
        type="text"
        v-model="registration.firstName"
        :rules="rules.name"
        :label="`${$t('registration.create.fields.firstName')} *`"
        required
        outlined
        validate-on-blur
        ref="firstName"
        @input="checkValidation('firstName')"
      ></v-text-field>

      <!-- LASTNAME -->
      <v-text-field
        id="lastName"
        type="text"
        v-model="registration.lastName"
        :rules="rules.name"
        :label="`${$t('registration.create.fields.lastName')} *`"
        required
        outlined
        validate-on-blur
        ref="lastName"
        @input="checkValidation('lastName')"
      ></v-text-field>

      <!-- EMAIL -->
      <v-text-field
        id="email"
        type="email"
        v-model="registration.email"
        :rules="rules.email"
        autocomplete="email"
        :label="`${$t('registration.create.fields.email')} *`"
        :hint="registration.email === '' ? $t('registration.create.hints.email') : ''"
        required
        outlined
        validate-on-blur
        ref="email"
        @input="checkValidation('email')"
      ></v-text-field>

      <!-- PASSWORD -->
      <password
        id="signup-password"
        v-model="registration.password"
        :rules="rules.password"
        validate-on-blur
        ref="signup-password"
        @input="checkValidation('signup-password', true)"
      ></password>

      <!-- ToS -->
      <v-checkbox
        v-model="registration.tos"
        :rules="rules.tos"
        required
        dense
      >
      <template v-slot:label>
        {{ $t('registration.create.fields.tos') }}&nbsp;
        <a
          @click.stop
          :href="downloadUrl"
          :download="$t('views.termsPDF')"
        >
          {{ $t('views.terms') }}
        </a>
      </template>
      </v-checkbox>
    </v-form>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters } from 'vuex';
import Password from '@/components/Shared/InputFields/Password.vue';
import {
  email, password, tos, name,
} from '@/utils/validators';

export default {
  components: {
    Password,
  },
  data() {
    return {
      registration: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
      },
      rules: {
        email,
        password,
        tos,
        name,
      },
    };
  },
  computed: {
    ...mapFields('registration', [
      'isFormValid',
    ]),
    ...mapGetters('registration', [
      'downloadUrl',
    ]),
  },
  methods: {
    async submit() {
      this.registration.firstName = this.registration.firstName.trim();
      this.registration.lastName = this.registration.lastName.trim();
      try {
        await this.$store.dispatch('registration/create', this.registration);
        await this.$store.dispatch('registration/nextStep');
      } catch (error) {
        if (error !== 'UserstatusUnconfirmedException') {
          this.$notify.error(error);
        }
      }
    },
    checkValidation(refName, children = false) {
      if (!this.isFormValid) {
        if (children) {
          this.$refs[refName].$children[0].validate();
        } else {
          this.$refs[refName].validate();
        }
      }
    },
  },
};
</script>
